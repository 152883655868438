<template>
  <div class="page page__content-wrapper">

    <div class="page__full-width">
      <page-item v-for="(item, idx) in productions" :content="item" :key="idx"></page-item>
    </div>

    <ButtonDownload
      :text-button="download.text"
      :url="download.url"
      class="page__download-block"
    />

    <BottomNavBlock :prev-link="bottomNav.pre" :next-link="bottomNav.next"/>
  </div>
</template>

<script>
import BottomNavBlock from "@/components/Elements/BottomNavBlock/BottomNavBlock"
import ButtonDownload from "@/components/Elements/ButtonDownload/ButtonDownload"
import PageItem from "@/components/Elements/PageItem/PageItem"

export default {
  components: {
    PageItem,
    ButtonDownload,
    BottomNavBlock
  },

  data() {
    return {
      bottomNav: {
        pre: {
          url: "/brand/equipments",
          text: "Экипировка"
        },
        next: {
          url: "/brand/event-decoration",
          text: "Оформление мероприятий"
        }
      },
      productions: [
        {
          type: "slider",
          sliderType: "ColoredSlider",
          title: "Блокнот",
          slides: [
            {
              bgColor: '#041C36',
              imgSlide: "souvenir-page/slider/notepad-01.png",
              isChecked: true
            },
            {
              bgColor: '#7A8B9C',
              imgSlide: "souvenir-page/slider/notepad-02.png",
              isChecked: false
            },
            {
              bgColor: '#D5DDE5',
              imgSlide: "souvenir-page/slider/notepad-03.png",
              isChecked: false
            }
          ],
          icons: [
            {
              url: "../download-file/09-Souvenirs/Steor-Notepad.zip",
              icon: "icon-zip.svg"
            }
          ]
        },
        {
          type: "image",
          title: "Кружка",
          images: [
            {
              img: "souvenir-page/souvenirs-01.png",
              icons: [
                {
                  url: "../download-file/09-Souvenirs/Steor-Mug.zip",
                  icon: "icon-ai.svg"
                }
              ]
            }
          ],
        },
        {
          type: "slider",
          sliderType: "ColoredSlider",
          title: "Термос",
          text: [
            "Полноцветный вариант наносится с помощью УФ-печати. В случае невозможности нанесения изображения по кругу, допускается нанесение только фирменного логотипа без паттерна. Для полноцветного варианта следует выбирать максимально темный синий цвет термоса.",
            "В случае невозможности нанесения полноцветного изображения, применяется техника гравировки. Цвета термосов с гравировкой: стальной, темно-синий, светло‑синий — близкие по оттенкам к фирменным."
          ],
          slides: [
            {
              bgColor: "#041C36",
              imgSlide: "souvenir-page/souvenirs-thermos-01.png",
              isChecked: true
            },
            {
              bgColor: "#004CD1",
              imgSlide: "souvenir-page/souvenirs-thermos-02.png",
              isChecked: false
            }
          ],
          icons: [
            {
              url: "../download-file/09-Souvenirs/Steor-Thermos.zip",
              icon: "icon-ai.svg"
            }
          ]
        },
        {
          type: "image",
          title: "USB-накопитель",
          images: [
            {
              img: "souvenir-page/souvenirs-02.png",
              icons: [
                {
                  url: "../download-file/09-Souvenirs/Steor-Flashdrive.zip",
                  icon: "icon-ai.svg"
                }
              ]
            }
          ]
        }
      ],
      download: {
        text: "Скачать все макеты",
        url: "09-Souvenirs/Steor-Equipment.zip"
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/var-style";
@import "../../../assets/style/text-style";
@import "../../../assets/style/page-block";
@import "../../../assets/style/buttons";

.page {
  margin-bottom: 192px;
  &__min-container {
    margin-bottom: 0;
  }
  &__link-block {
    margin-left: 23px;
  }
}

///deep/.download-block {
//  margin-left: 24px;
//}

/deep/.page-item {
  .slider__download-block {
    margin-top: -90px;
  }
}

@media screen and (max-width: 1540px) {
  .page__content-wrapper {
    padding-left: 64px;
  }
}

@media screen and (max-width: 1456px) {
  .page {
    margin-bottom: 263px;
  }
}


@media screen and (max-width: 1320px) {
  .page__content-wrapper {
    padding-left: 0;
  }
}

@media screen and (max-width: 1216px) {
  .page__link-block.download-block {
    display: none;
  }
}

@media screen and (max-width: 1090px) {
  .page {
    margin-top: 0;
    padding-top: 18px;
  }
}

@media screen and (max-width: 896px) {
  .pages-wrap {
    .container {
      .page {
        &.page__content-wrapper {
          padding-left: 16px;
          padding-right: 16px;
        }
      }
    }
  }

  .page {
    padding-top: 48px;
    margin-bottom: 191px;
    &__full-width {
      width: 100%;
    }

    &__download-block {
      display: none;
    }
  }

  /deep/.colored-slider {
    .slider__slides-item {
      img {
        object-fit: contain;
        max-width: 235px;
      }
    }
  }
}


</style>
